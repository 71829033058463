import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import showdown from 'showdown';

export const StyledMarkdownContent = styled.div`
  h1 { font-size: ${props => props.theme.font.size2}; }
  h2 { font-size: ${props => props.theme.font.size3}; }
  h3 { font-size: ${props => props.theme.font.size4}; }
  h4 { font-size: ${props => props.theme.font.size5}; }
  h5 { font-size: ${props => props.theme.font.size5}; }
  p {
    font-size: ${props => props.theme.font.size5};
    margin: .5rem 0;
  }
  table td,
  table th {
    border: 1px #000 solid;
    padding: .5rem;

    &:empty {
      display: none;
    }
  }
  ul {
    list-style: disc;
    margin-left: 1rem;
  }
  ol {
    list-style: decimal;
    margin-left: 1rem;
  }
  ul {
    list-style: disc;
    margin-left: 1rem;
  }
  ol {
    list-style: decimal;
    margin-left: 1rem;
  }
  ul {
    list-style: disc;
    margin-left: 1rem;
  }
  ol {
    list-style: decimal;
    margin-left: 1rem;
  }
`;

const addBreakLinesInsideCustomTags = source => source
  .replace(/<text-align-right>/g, '<text-align-right>\n')
  .replace(/<\/text-align-right>/g, '\n</text-align-right>')
  .replace(/<text-align-center>/g, '<text-align-center>\n')
  .replace(/<\/text-align-center>/g, '\n</text-align-center>')
;

const convertCustomTags = source => source
  .replace(/<text-align-right>/g, '<div style="text-align: right;">')
  .replace(/<\/text-align-right>/g, '</div>')
  .replace(/<text-align-center>/g, '<div style="text-align: center;">')
  .replace(/<\/text-align-center>/g, '</div>')
;

const converter = new showdown.Converter({ tables: true });

const MarkdownContent = ({ source }) => source ? (
  <StyledMarkdownContent dangerouslySetInnerHTML={{
    __html: convertCustomTags(converter.makeHtml(addBreakLinesInsideCustomTags(source)))
  }} />
) : null;

MarkdownContent.propTypes = {
  source: PropTypes.string.isRequired,
};

export default MarkdownContent;
