import styled from 'styled-components';

import slickCSS from '!!raw-loader!./slick.css';
import slickThemeCSS from '!!raw-loader!./slick-theme.css';

const Styled = {};

Styled.Container = styled.div`
  ${slickCSS};
  ${slickThemeCSS};
`;

export default Styled;
