import React from 'react';
import PropTypes from 'prop-types';
import ReactSlick from 'react-slick';

import SC from './style';

const GallerySlider = ({ slides, setting }) => (
  <SC.Container>
    <ReactSlick {...setting}>
      {slides.map(({ image: source }) => (
        <div key={source}>
          <img src={source} alt="" />
        </div>
      ))}
    </ReactSlick>
  </SC.Container>
);

GallerySlider.propTypes = {
  slides: PropTypes.array.isRequired,
  setting: PropTypes.object,
};

GallerySlider.defaultProps = {
  setting: {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  },
};

export default GallerySlider;
