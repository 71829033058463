import styled from 'styled-components';

const Styled = {};

Styled.ContactContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;

  > * {
    flex: 1;
  }
`;

export default Styled;
