import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import SC from './style';
import Common from '@/components/commonStyle';

import Head from '@/components/Head';
import PageHeader from '@/components/PageHeader';
import GallerySlider from '@/components/GallerySlider';
import ContactContent from '@/components/ContactContent';
import MarkdownContent from '@/components/MarkdownContent';
import FormattedDate from '@/components/FormattedDate';
import Dialog, { useDialog, useKeyPressEscape } from 'react-dialog';

const removeHTMLTags = source => source.replace(/<[^>]*>?/gm, '');
const removeUselessCharacters = source => source
  .replace(/#/g, '')
  .replace(/\*/g, '')
  .replace(/\n/g, '')
  .replace(/\r/g, '')
  .replace(/&nbsp;/g, '');

const PressPost = ({
  post,
  language,
  localizedContent: { title = '', description = '', brief_description = '', file = null } = {},
  localizedContact: { left_column = '', right_column = '' } = {},
}) => {
  const { isDialogOpen, setDialogOpen, setDialogClose } = useDialog(false);
  const isKeyPressEscape = useKeyPressEscape();
  if (isKeyPressEscape && isDialogOpen) setDialogClose();

  const frontmatter = _.get(post, 'data.markdownRemark.frontmatter') || {};
  const { date, contact_info, hidden } = frontmatter;
  const gallery = (frontmatter.gallery || []).map(gallery => {
    const image = _.get(gallery, 'childImageSharp.fluid.src') || null;
    return image ? { image } : null;
  }).filter(image => image !== null);

  // No content
  if (hidden === true || (title === '' && description === '')) {
    return (
      <>
        <SC.CenterContent>
          <FormattedMessage id="site.no-localized-content" tagName="p" />
        </SC.CenterContent>
        <SC.CenterContent>
          <SC.BackButton to={`/${language}/press/`}>
            <FormattedMessage id="site.back" />
          </SC.BackButton>
        </SC.CenterContent>
      </>
    );
  }

  const metaDescription = brief_description || removeHTMLTags(removeUselessCharacters(description)).substring(0, 130);

  return (
    <>
      <Head title={title} description={metaDescription} />
      <PageHeader title={title} />
      <SC.GalleryContainer>
        {gallery && gallery[0] && gallery[0].image && (
          <SC.SliderContainer>
            <img src={gallery[0].image} alt="" />
            <SC.PlayButton type="button" onClick={() => setDialogOpen()}>
              <FormattedMessage id="site.gallery.play" />
            </SC.PlayButton>
          </SC.SliderContainer>
        )}
        <SC.GalleryInfo>
          <p className="is-size-6 has-text-weight-bold"><FormattedMessage id="press.publishDate" /></p>
          <time dateTime={date} className="is-size-6">
            <FormattedDate
              value={date}
              year="numeric"
              month="short"
              day="numeric"
            />
          </time>
          {file && (
            <SC.DownloadFileLink href={file} target="_blank">
              <FormattedMessage id="press.download" />
            </SC.DownloadFileLink>
          )}
        </SC.GalleryInfo>
      </SC.GalleryContainer>

      {description && (
        <SC.DescriptionContent>
          <MarkdownContent source={description} />
        </SC.DescriptionContent>
      )}

      { (left_column || right_column) && contact_info ? (
        <ContactContent left_column={left_column} right_column={right_column} />
      ): null }

      {
        /* eslint-disable react/display-name */
        gallery && (
          <Dialog
            open={isDialogOpen}
            onBackdropClick={() => setDialogClose()}>
            <Common.CloseButton
              type="button"
              onClick={() => setDialogClose()} />
            <GallerySlider setting={{
              customPaging: (i) => <img src={gallery[i].image} />,
              dots: true,
              dotsClass: 'slick-dots slick-thumb',
              infinite: true,
              autoplay: true,
              thumbs: true,
              autoplaySpeed: 3000,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
            }} slides={gallery} />
          </Dialog>
        )
        /* eslint-enable react/display-name */
      }
    </>
  );
};

PressPost.propTypes = {
  post: PropTypes.object,
  language: PropTypes.string,
  localizedContent: PropTypes.object,
  localizedContact: PropTypes.object,
};

export default PressPost;
