import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Styled = {};

Styled.CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 20px;
  cursor: pointer;
  background: transparent;
`;

Styled.PlayButton = styled.button`
  position: absolute;
  left: 20px;
  bottom: 20px;
  border: 3px solid #fff;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: ${props => props.theme.font.size5};
  font-weight: bold;
  text-align: center;
  color: #fff;
  vertical-align: middle;
  cursor: pointer;
  background: rgba(0, 0, 0, .45);

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.color.link};
    border-color: ${props => props.theme.color.link};

    &::after {
      border-left-color: ${props => props.theme.color.link};
    }
  }

  &::after {
    content: "";
    display: inline-block;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent ${props => props.theme.color.primaryInvert};
  }
`;

Styled.GalleryContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px;
  border-radius: 15px;
  overflow: hidden;
  background: #ebf6fc;

  @media ${props => props.theme.device.above.tablet} {
    flex-direction: row;
  }
`;

Styled.SliderContainer = styled.div`
  @media ${props => props.theme.device.above.tablet} {
    width: calc(100% - 240px);
    max-width: 640px;
  }
`;

Styled.GalleryInfo = styled.div`
  padding: 20px 20px 100px;

  @media ${props => props.theme.device.above.tablet} {
    flex-basis: 240px;
    padding: 20px;
  }
`;

Styled.DescriptionContent = styled.div`
  padding: 20px;
`;

Styled.ContactContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;

  > * {
    flex: 1;
  }
`;

Styled.CenterContent = styled.div`
  padding: 20px;
  text-align: center;
`;

Styled.BackButton = styled(props => <Link {...props} />)`
  display: inline-block;
  position: relative;
  height: 27px;
  font-size: ${props => props.theme.font.size6};
  line-height: 26px;
  text-align: center;
  min-width: 90px;
  border-radius: 20px;
  background: #eef6fa linear-gradient(to right, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
  cursor: pointer;
`;

Styled.DownloadFileLink = styled.a`
  display: block;
  margin-top: 2rem;
`;

export default Styled;
