import React from 'react';
import PropTypes from 'prop-types';

import MarkdownContent from '@/components/MarkdownContent';

import SC from './style';

const PressPost = ({
  left_column,
  right_column,
}) => (
  <SC.ContactContent>
    <MarkdownContent source={left_column} />
    <MarkdownContent source={right_column} />
  </SC.ContactContent>
);

PressPost.propTypes = {
  left_column: PropTypes.string,
  right_column: PropTypes.string,
};

PressPost.defaultProps = {
  left_column: '',
  right_column: '',
};

export default PressPost;
