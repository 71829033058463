import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import App from '@/components/App';
import PageMainContent from '@/components/PageMainContent';
import PressPost from '@/components/PressPost';
import trackPage from '@aa/trackPage';
import SAMMap from '@aa/SAMMap';

const PageInjectedIntl = ({ pageContext: { data, pressContactData } }) => {
  const { locale: language } = useIntl();
  const localizedContent = _.get(data, 'data.markdownRemark.frontmatter.languages.content') || {};
  const contact_info = _.get(data, 'data.markdownRemark.frontmatter.contact_info') || '';
  const localizedContact = _.get(pressContactData, `${contact_info}.data.markdownRemark.frontmatter.languages.content`) || {};
  const frontmatter = _.get(data, 'data.markdownRemark.frontmatter') || {};
  const {
    date_yyyymmdd,
    slug,
  } = frontmatter;

  trackPage({
    pageId: `${SAMMap[language].press.pageId}${date_yyyymmdd}_${slug}`,
    ...SAMMap[language].common
  });

  return (
    <PageMainContent>
      <PressPost
        language={language}
        post={data}
        localizedContent={localizedContent}
        localizedContact={localizedContact}
      />
    </PageMainContent>
  );
};

PageInjectedIntl.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    defaultLanguage: PropTypes.string,
    data: PropTypes.any,
    pressContactData: PropTypes.any,
  }),
};

const Page = ({ pageContext }) => (
  <App language={pageContext.language} defaultLanguage={pageContext.defaultLanguage}>
    <PageInjectedIntl pageContext={pageContext} />
  </App>
);

Page.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    defaultLanguage: PropTypes.string,
    data: PropTypes.any,
    pressContactData: PropTypes.any,
  }),
};

export default Page;
